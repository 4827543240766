// @ngInject
function acpCardownerDomainModel(acpCardownerDomainClient, nsUtil) {
  this.edit = function(id, profile) {
    return acpCardownerDomainClient.edit(
      nsUtil.assign(profile, {
        id: id
      })
    );
  };
}

export default acpCardownerDomainModel;
