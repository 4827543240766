// @ngInject
function acpCardownerDomainClient(webapiResource) {
  this.getById = webapiResource({
    path: '/v1/cardowners/:id',
    method: 'GET'
  });

  this.edit = webapiResource({
    path: '/v1/cardowners/:id',
    method: 'PUT'
  });
}

export default acpCardownerDomainClient;
